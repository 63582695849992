<template>
    <div>
        <vs-popup  fullscreen :active.sync="popupActive" @close="close" :title="$t('emr.appt.Recommend')">
            <div>
                <vs-col class="sm:px-5 px-0 mb-base" vs-w="8" vs-xs="12" v-if="allow_shop">
                    <p class="mr-4 font-bold"> {{$t('emr.appt.Recommend')}}</p><br>
                    <vs-row>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5" vs-xs="12">
                                <div class=" flex items-center">
                                    <p class="mr-4"> {{$t('emr.appt.PatientName')}}{{ ":" }}{{ pname }}</p>
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6" vs-xs="12">
                                <p class="mr-4 cd-p-pointer text-primary"  @click="opensetProductPopup()" >{{$t('emr.appt.SetProduct') }}</p>
                                <p class="cd-p-pointer text-primary"  @click="openUseProductPopup()" >{{ $t('emr.appt.ChooseProduct') }}</p>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1" vs-xs="12">
                                <div class=" flex items-center">
                                    <p class="cd-p-pointer text-primary" @click="openInsertRecommPopup()" >{{ $t('emr.appt.SendRecommend') }}</p>
                                </div>
                            </vs-col>
                        </vs-row>
                        <div>
                            <v-select 
                                class="w-56 cd-select bg-white"
                                ref="icd"
                                multiple
                                autocomplete="on"
                                v-model="PRODUCT"
                                :options="optionProduct" 
                                :components="{OpenIndicator}"
                                :placeholder="$t('emr.appt.SearchRecommend')"
                                @search="getCartProduct"
                                @option:selected="insertProduct"
                                >
                            </v-select>
                        </div>
                        <div style="height: 250px;">
                            <ag-grid-vue style="height: 100%;"
                            class="ag-theme-balham"
                            :columnDefs="columnRecomm"
                            :rowData="reportrecomm"
                            :singleClickEdit="true"
                            :stopEditingWhenCellsLoseFocus="true"
                            @cellClicked=openCartPopup($event)>
                            </ag-grid-vue>
                        </div>
                </vs-col>
                        <p class="mr-4 font-bold">{{ $t('emr.appt.MyRecommend') }}</p><br>
                <vs-col vs-align="center" vs-w="4" vs-xs="12">
                            <div class=" mb-2" v-for="(item, index) in recomm"  :key="index">
                                <div class="flex justify-between">
                                    <span class=" mr-4">{{ item.name }}</span>
                                    <span class="cd-date">{{ item.created_at }}</span>
                                </div>
                                <div>
                                </div>
                            </div>
                </vs-col>
            </div>
        </vs-popup>
        <!-- 新增常用商品 -->
        <vs-popup :active.sync="setProductPopup" @close="closeSetProductPopup" :title="$t('emr.appt.ChooseProduct')" style="z-index:200001 !important;">
            <div class="cd-form-group text-center"  v-if="!setProductList[0]">
                <span>{{ $t('emr.appt.ChooseProductError') }}</span>
            </div>
            <vs-checkbox v-for="(item, index) in setProductList" :key="index" v-model="checkSetProduct[index]" >
                <p class="py-1" style="word-break: break-word;">{{item.name}}</p>
            </vs-checkbox>
            <div class="flex justify-center">
                <vs-button class="cd-button-2w" type="border" :disabled="!setProductList[0]" @click="setProduct">{{$t('popup.save')}}</vs-button>
                <vs-button class="cd-button-2w" type="border" style="border-color: black;color: black;"  @click="closeSetProductPopup">{{$t('popup.cancel')}}</vs-button>
            </div>
        </vs-popup>
        <!-- 套用常用商品 -->
        <vs-popup :active.sync="useProductPopup" :title="$t('emr.appt.UseMyProduct')" style="z-index:200001 !important;">
            <vs-row>
                <vs-col v-for="(item, index) in doctorProducts"  :key="index">
                    <div class="flex justify-between">
                    <vs-checkbox  v-model="checkUseProduct[index]">{{ item.name }}</vs-checkbox>
                    <!-- <p class="" style="word-break: break-word;">{{item.name}}</p> -->
                    <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hovewwwr:text-primary" @click="deleteDoctorProducts(item.product_id)"></feather-icon>
                    </div>
                </vs-col>
                <div class="cd-form-group text-center"  v-if="!doctorProducts[0]">
                <span>{{$t('emr.appt.SetRecommProduct')}}</span>
                </div>
            </vs-row>
            <div class="flex justify-center">
                <vs-button class="cd-button-2w" type="border" @click="useProduct" :disabled="!doctorProducts[0]">{{$t('emr.appt.UseProduct')}}</vs-button>
                <vs-button class="cd-button-2w" type="border" style="border-color: black;color: black;"  @click="closeUseProductPopup">{{$t('popup.cancel')}}</vs-button>
            </div>
        </vs-popup>
        <!-- 新增推薦商品視窗 -->
        <vs-popup :active.sync="insertRecommendPopup" @close="closeRecommPopup" :title=" $t('emr.appt.AddRecommend')" style="z-index:200001 !important;">
            <div class="cd-form-group text-center">
                {{$t('emr.appt.CheckSend') }}<br>
            </div>
            <div class="flex justify-center">
                <vs-button class="cd-button-2w" v-if="!insertRecommStatus" type="border" @click="insertRecomm()">{{ $t('popup.save') }}</vs-button>
                <vs-button class="cd-button-2w" color="success" v-else type="border" >{{$t('emr.appt.DataProcessing')}}</vs-button>
                <vs-button class="cd-button-2w" type="border" style="border-color: black;color: black;" @click="closeRecommPopup">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
        <!-- 推薦商品視窗 -->
        <vs-popup :active.sync="cartImagePopupActive" :title="$t('emr.appt.RecommendProduct')" style="z-index:200001 !important;"> 
            <span class="font-bold">{{ cartProductName+ cartProductPrice+'元' }}</span>
            <iframe width="100%"
            height="500vh"
            :src="cartProductImage"
            frameborder="0" allowfullscreen></iframe>
            <vs-col vs-type="flex" vs-justify="space-around" vs-align="center">
                <vs-button class="cd-button-4w" type="border"  @click="goToCart" >{{ $t('emr.appt.goProductShop')}}</vs-button>
                <vs-button class="cd-button-2w mr-4" type="border" style="border-color: black;color: black;" @click="closeCartpopup">{{ $t('popup.cancel') }}</vs-button>
            </vs-col>
        </vs-popup>
    </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue";
import vSelect from 'vue-select';
import {searchcartproduct, insertRecommend, getAllowShop, getRecommend,getDoctorProducts,setProduct,deleteProductFromDB } from '@/api/user'
export default {
    components:{
        vSelect,
        AgGridVue
    },
    props: {
        productPopupActive: {
            type: Boolean,
            default: false,
            required: true
        },
        pid:{ required: true },
        sn_id:{ required: true },
        pname:{ required: true },
    },
    data(){
        return{
            cartProductName:"",
            cartProductPrice : 0,
            cartProductUrl: "",
            cartImagePopupActive:false,
            cartProductImage:"",
            recomm:[],
            insertRecommendPopup:false,
            recommendList:[],
            RXPopup: false,
            insertRecommStatus:false,
            reportrecomm:[],
            optionProduct:[],
            PRODUCT:[],
            allow_shop:false,
            doctorProducts:[],
            setProductPopup:false,
            useProductPopup:false,
            checkSetProduct: [],
            setProductList:[],
            checkUseProduct:[],
            // 去掉下拉式選單箭頭
            OpenIndicator: {
            render: createElement => createElement('span', {class: {'toggle': true}}),
            },
        }
    },
    mounted() {
    },
    computed: {
        popupActive: {
            get() {
                if(this.productPopupActive) {
                    this.getAllowShop()
                }
                return this.productPopupActive
            },
            set(v) {
                return v
            },
        },
        windowWidth() {
        return this.$store.state.windowWidth
        },
        partnerid() {
        return this.$store.getters.partner
        },
                // 已儲存推薦商品
        columnRecomm() {
            let rs = []
            var nameWidth = this.windowWidth-100-80-80-80-80-80-80-80-120-50-69 
            nameWidth = nameWidth < 150 ? 150 : nameWidth;
            //編輯推薦商品
            
                 rs = [
                    { headerName: this.$t('emr.appt.ProductName'), field: "name", width: 150 },
                    { headerName: this.$t('emr.appt.ProductNumber'), field: "quantity", width:100,editable: true, cellStyle: {color: '#003f8e'}  },
                    { headerName: this.$t('emr.appt.Price'), field: "price", width:100 },
                    { headerName: '', field: "image", width: 100,
                    cellRenderer: function() {
                        return '<span><i class="cursor-pointer" style="color:#003f8e;">檢視圖片</i></span>'
                    }
                    },
                    { headerName: "", field: "del", width: 50,
                    cellRenderer: function() {
                        return '<span><i class="material-icons cursor-pointer">clear</i></span>'
                    }
                    },
                    { headerName: this.$t('emr.appt.Manufacturer'), field: "manufacturer", width:100 }, 
                    { headerName: this.$t('emr.appt.Description'), field: "description", width:nameWidth },
                ]
            
            return rs
        },
    },

    methods: {
        // 視窗通知 
        notify(color, title, text){
            this.$vs.notify({
            color: color,
            title: title,
            text: text,
            position:'top-center'
            })
        },
        close(){
            this.recomm=[]
            this.reportrecomm=[]
            this.$emit("closePopup")
        },
        //搜尋商品
        getCartProduct(word){
            let payload = {
                query : word,
                partner_id : this.partnerid,
                cart_token:localStorage.getItem('cart_token')
            }
            if(!word) return false
            let duplicate = false
            this.optionProduct = []
            searchcartproduct(payload).then(res => {
                if(res.data.data=='') return false
                if(res.data.status=="ERROR TOKEN"){
                    localStorage.removeItem('cart_token')
                    this.getAllowShop()
                }
                res.data.data.forEach((v)=>{
                    this.reportrecomm.forEach((v2)=>{
                        if (v.name == v2.name) {
                            console.log(v,v2)
                            duplicate = true
                        }
                    })
                if(duplicate) {
                    duplicate = false
                    return false
                }
                this.optionProduct.push({
                    "label": '('+v.product_id+')'+v.name,
                    "id":v.product_id,
                    "name": v.name,
                    "price": v.price,
                    "quantity":v.quantity,
                    "manufacturer": v.manufacturer,
                    "description":v.description,
                    "url":v.url,
                    "image":v.image
                    })
                    })
                
            })
        },
        //放入推薦商品表格
        insertProduct(){
            this.reportrecomm.push({
                "name": this.PRODUCT[0].name,
                "product_id":this.PRODUCT[0].id,
                "price": this.PRODUCT[0].price,
                "quantity":1,
                "manufacturer": this.PRODUCT[0].manufacturer,
                "description":this.PRODUCT[0].description,
                "url":this.PRODUCT[0].url,
                "image":this.PRODUCT[0].image
            })
            this.PRODUCT = []
        },
        //從表格刪除商品
        deleteProduct(event=''){
            this.reportrecomm = _.remove(this.reportrecomm, function(n) {
                return n.name != event.data.name;
            });
        },
        setProduct(){
            var _self = this
            let payload ={
            data:[],
            partner_id:localStorage.getItem('partnerid')
            }
            let number=0
            console.log(_self.setProductList)
            _self.checkSetProduct.forEach(function(v,i){
                if(_self.setProductList[i].id==''){
                    return false
                }
                if(v){
                    payload.data[number]=
                        {"product_id":_self.setProductList[i].id}
                        number++
                }
            })    
            setProduct(payload).then(res => {
                if(res.data.status=='OK'){
                    _self.notify('danger', _self.$t('message.create_success'))
                }
            })
            this.getDoctorProducts()
            this.setProductList=[]
            this.setProductPopup = false
        },
        //新增推薦商品
        insertRecomm(){
            this.insertRecommStatus = true

            let payload ={
                spid:this.pid,
                queue_id:'',
                sn_id:this.sn_id,
                data:[],
                cart_token:localStorage.getItem('cart_token'),
                partner_id:localStorage.getItem('partnerid')
            }
            let number=0
            this.reportrecomm.forEach((v)=>{
                    if(v.quantity>0){
                    payload.data[number]=
                    {   "product_id":v.product_id,
                        "quantity":v.quantity,
                        "snapshot":{"name":v.name,"price":v.price}}
                    number++
                    }
                })

             //選取推薦產品數量才呼叫,無法用disable判斷資料
            if(payload.data[0]){
                insertRecommend(payload).then(res => {
                if(res.data.status=='OK'){
                    this.notify('danger', this.$t('message.create_success'))
                    this.insertRecommendPopup = false
                    this.insertRecommStatus = false
                    this.getRecommend()
                }else if(res.data.status=='CHANGE OK'){
                    this.notify('danger', this.$t('message.update_success'))
                    this.insertRecommendPopup = false
                    this.insertRecommStatus = false
                    this.getRecommend()
                }
            })
            }else{
                this.notify('danger', this.$t('emr.appt.cart_error')) 
            }
            this.reportrecomm=[]
        },
        //開啟商城預覽圖片視窗
        openCartImagePopup(name,price,image,url){
            this.cartProductName = name
            this.cartProductPrice = price   
            this.cartProductImage= image
            this.cartProductUrl = url
            this.cartImagePopupActive = true
        },
        //關閉商城預覽圖片視窗
        closeCartpopup(){
            this.cartImagePopupActive = false
            this.cartProductName = ''
            this.cartProductPrice = 0   
            this.cartProductImage= ''
            this.cartProductUrl=''
        },
        //前往商城 手機版開新分頁還有問題
        goToCart(){
            if(this.device==2){
                {window.open(this.cartProductUrl, '_system')}
            }else{
                {window.open(this.cartProductUrl, '_blank')}
            }
        },
        //檢查partner商城開關
        getAllowShop(){
            let payload = {
                "partner_id":localStorage.getItem('partnerid')
            }
            if(localStorage.getItem('cart_token')) {
                this.allow_shop = true
                this.getRecommend()
                this.getDoctorProducts()
                return
            }
            getAllowShop(payload).then(res => {
                if(res.data.status=='OK'){
                if(res.data.token){
                    this.allow_shop = true
                    localStorage.setItem('cart_token',res.data.token)
                    this.getRecommend()
                    this.getDoctorProducts()
                }
                }
                })
        },
        //取推薦商品shop recommm
        getRecommend(){
            let payload = {
                spid:this.pid,
                queue_id:'',
                sn_id:this.sn_id,
                cart_token:localStorage.getItem('cart_token'),
                partner_id:localStorage.getItem('partnerid')
            }
            getRecommend(payload).then(res => {
                if(res.data.status=='OK'){
                    this.recomm = res.data.data
                }else if(res.data.status=='ERROR TOKEN'){
                    localStorage.removeItem('cart_token')
                    this.getAllowShop()
                }
                })
        },
        //開啟寫入推薦商品視窗
        openInsertRecommPopup(){
            this.insertRecommendPopup = true

        },
        //關閉寫入推薦商品視窗
        closeRecommPopup(){
            this.insertRecommendPopup = false
            this.recommendList =[]
        },
        //以存取商品開視窗
        openCartPopup(event){
            if(event.colDef.field == "image"){
                this.openCartImagePopup(event.data.name,event.data.price,event.data.image,event.data.url)
            }else if(event.colDef.field=="del"){
                this.deleteProduct(event)
            }else{
                return;
            }
        },
        //取常用商品
        getDoctorProducts(){
            let payload = {
                "partner_id":localStorage.getItem('partnerid'),
                "cart_token":localStorage.getItem('cart_token')
            }
            getDoctorProducts(payload).then(res => {
                if(res.data.status=='OK'){
                    console.log(res.data.data)
                    this.doctorProducts = res.data.data
                }else if(res.data.status=='ERROR TOKEN'){
                    localStorage.removeItem('cart_token')
                    // this.getAllowShop()
                }
                })
        },
        //開啟新增常用商品
        opensetProductPopup(){
            this.reportrecomm.forEach((v,i)=>{
                this.setProductList.push({
                    "id":v.product_id,
                    "name": v.name,
                })
                    this.checkSetProduct[i]=true
            })
            this.setProductPopup = true
        },
         //關閉新增常用商品
        closeSetProductPopup(){
            this.setProductList=[]
            this.setProductPopup = false
        },
         //開啟使用常用商品
        openUseProductPopup(){
            this.getDoctorProducts()
            this.useProductPopup = true
        },
        // //關閉使用常用商品
        closeUseProductPopup(){
            this.useProductPopup = false
        },        
        //套用常用商品
        useProduct(){
        var _self = this
        let duplicate = false
        _self.checkUseProduct.forEach(function(v,i){
            if(v){
                _self.reportrecomm.forEach((v2)=>{
                if (_self.doctorProducts[i].name == v2.name) {
                    duplicate = true
                }
                })
                if(duplicate) {
                duplicate = false
                return false
                }
                _self.reportrecomm.push({
                "name": _self.doctorProducts[i].name,
                "product_id":_self.doctorProducts[i].product_id,
                "price": _self.doctorProducts[i].price,
                "quantity":1,
                "manufacturer": _self.doctorProducts[i].manufacturer,
                "description":_self.doctorProducts[i].description,
                "url":_self.doctorProducts[i].url,
                "image":_self.doctorProducts[i].image
                })
            }
        })
        this.checkUseProduct=[]
        this.useProductPopup = false
        },
        async  deleteDoctorProducts(item){
            let payload ={
            product_id:item,
            partner_id:localStorage.getItem('partnerid')
            }
            await deleteProductFromDB(payload).then(res => {
                if(res.data.status=='OK'){
                    console.log(res.data.data)
                    this.notify('danger', this.$t('message.delete_success')) 
                }
            })
            await this.getDoctorProducts()
        }
    }
}
</script>